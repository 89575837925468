import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Column, Container, Row } from '../Grid';
import Image from '../Image';
import Quote from '../Quote';
import Resource from '../Resource';
import Action from '../Resource/Action';
import Video from '../Video';
import './styles.scss';

const EpisodePlayer = ({
  about,
  episodes,
  playerLabel,
  className,
  description,
  guideImg,
  guideTitle,
  guideDescription,
  guideAudience,
  guideDownloadUrl,
  guideSize,
  background,
}) => {
  const [activeIndex, setActiveIndex] = useState(0); // zero-based index
  const viewerRef = useRef(null);

  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          id={episode.title
            .replace(/[^a-zA-Z ]/g, '')
            .replace(/ /g, '-')
            .toLowerCase()}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div>
            <h5>{episode.title}</h5>
            <p className="small">
              {episode.duration} | {episode.subject}
            </p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={episode.dexSrc}
        poster={episode.posterSrc}
        className="video-player"
        title={episode.title}
      />
    );
  };

  const renderCurrentQuote = () => {
    const episode = episodes[activeIndex];
    return <Quote {...episode.quote} />;
  };

  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <>
        <Container fullWidth noPadding>
          <Row>
            <Column large={8}>
              <div className="episode-player__intro pb-2 pr-4">
                <h2>{episode.descriptionTitle}</h2>
                <p>{episode.description}</p>
                {console.log(episode.quote)}
                {episode.actions &&
                  episode.actions.map((action, i) => (
                    <div className="episode-player__actions">
                      <Action key={i} {...action} />
                    </div>
                  ))}

                {episode.conversationStarters && (
                  <>
                    <h3 className="mt-3 mb-1">
                      Classroom Conversation Starters
                    </h3>
                    <div>
                      <ul>
                        {episode.conversationStarters.map(
                          (conversationStarter, i) => (
                            <li key={i}>
                              <p>{conversationStarter}</p>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                )}
                {about && (
                <>
                  <h3 className="pt-1 mt-3 mb-1">About this Series</h3>
                  {typeof about === 'string' ? <p>{about}</p> : <>{about}</>}
                </>
              )}
              </div>
            </Column>
            <Column large={4}>
              <Resource
                tophat="Educator Guide"
                img={guideImg}
                title={guideTitle}
                description={guideDescription}
                audience={guideAudience}
                actions={[
                  {
                    label: 'Download',
                    download: guideDownloadUrl,
                    metadata: `PDF, ${guideSize}`,
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <>
      <Container
        noPadding
        fullWidth
        className={`episode-player episode-player-upper ${className}`}
      >
        <div className={`episode-player__label-title ${background}`}>
          <h2 className="pt-5 pb-2">{playerLabel}</h2>
        </div>
        <div className={`episode-player__content-wrapper ${background}`}>
          <div ref={viewerRef} className="episode-player__video-wrapper">
            <div className="episode-player__video">{renderCurrentVideo()}</div>
            <div className="episode-player__video-desktop-spacer"></div>
          </div>
          <div className="episode-player__episode-wrapper">
            <div className="episode-player__episode-desktop-spacer"></div>
            <div className="episode-player__episodes">{renderEpisodes()}</div>
            {description && (
              <div className="episode-player__description">{description}</div>
            )}
          </div>
        </div>
      </Container>
      <Container
        fullWidth
        className={`episode-player episode-player-lower ${className}`}
      >
        <div className={`episode-player__content-wrapper`}>
          <div className="episode-player__current-data">
            {renderCurrentData()}
          </div>
        </div>
      </Container>
      {renderCurrentQuote()}
    </>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  guideImg: PropTypes.string,
  guideTitle: PropTypes.string,
  guideDescription: PropTypes.string,
  guideAudience: PropTypes.string,
  guideDownloadUrl: PropTypes.string,
  guideSize: PropTypes.string,
  about: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
  guideImg: '',
  guideSize: '',
  background: 'vts-yellow'
};

export default EpisodePlayer;
