import { graphql } from 'gatsby';
import React from 'react';
import EpisodePlayer from '../components/EpisodePlayer';
import { Container } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Section from '../components/Section';

const HitSong = ({ data }) => (
  <Layout title={'Anatomy of Hit Song'}>
    <Hero
      mediaSource="VTS2-hero.jpg"
      textOverlay="VIDEO TOPIC SERIES"
      className="mb-0"
    />

    {/* Intro */}
    <Section noPadding className="vts-intro">
      <Container fullWidth className="vts-intro__container" noPadding>
        <div className="vts-intro__container__text">
          <Quicklinks
            links={[
              {
                target: '#videos',
                label: 'The Musician',
                clickTarget: '#the-musician',
              },
              {
                target: '#videos',
                label: 'The Music Producer',
                clickTarget: '#the-music-producer',
              },
              {
                target: '#videos',
                label: 'The Sound Engineer',
                clickTarget: '#the-sound-engineer',
              },
            ]}
          />
          <h1 className="mt-2">COOKING UP COUNTRY MUSIC HITS WITH STEAM</h1>
          <p className="large mt-2 pb-3">
          Take students behind the scenes of Country Music production to learn what goes into the making of a hit song. Students will go on a virtual tour of making a song and learn how STEAM is an essential ingredient every step of the way.
          </p>
        </div>
        <div className="vts-intro__container__img-div">
          <Image
            className="vts-intro__container__img-div__img record"
            filename="VTS2-graphic-vinylrecord.svg"
          />
        </div>
      </Container>
    </Section>

    {/* Videos */}
    <Section noPadding className="vts-videos">
      <Container fullWidth id="videos" noPadding className="bg-white">
        <EpisodePlayer
          playerLabel={
            <>
              <i>Anatomy of a Hit Song</i>&nbsp;Video Topic Series
            </>
          }
          description={''}
          className="pt-0"
          episodes={data.allVideoTopicsYaml.edges.map((edge) => edge.node)}
          about={
            <>
              <p>
              This three-part video series shines a light on the multiple STEAM professions collaborating in harmony to make Country Music one of the most popular genres in the world. Standards-aligned classroom activities, discussions, and career connections bring lessons to life as students engage with what they’ve just seen.
              </p>
            </>
          }
          guideImg="vts-hitSong-resource-EdGuide.jpg"
          guideTitle="Anatomy of a Hit Song | Educator Guide"
          guideDescription="Designed with flexibility in mind, as well as different levels of access to devices, the lessons in this bundle can be used in any order or independent of each other. Accompanying activities and discussion prompts bring the action from the screen to the classroom to engage students in active learning. Collaborate with your school’s music teacher to further utilize and emphasize the creative Arts portion of STEAM education and turn theory into practice."
          guideAudience="Grades 3–8"
          guideDownloadUrl="/docs/CMA-VTS-EdGuide-AnatomyHitSong.pdf"
          guideSize="288 KB"
          background={`vts-blue`}
        />
      </Container>
    </Section>
  </Layout>
);

export const query = graphql`
  query HitSong {
    allVideoTopicsYaml (filter: {topic: {eq: "HitSong"}}){
      edges {
        node {
          id
          topic
          dexSrc
          conversationStarters
          posterSrc
          descriptionTitle
          description
          title
          duration
          subject
          actions {
            label
            download
            metadata
          }
          quote {
            text
            source
            backgroundImage
            className
            width
          }
        }
      }
    }
  }
`;

export default HitSong;
